import React, { useState } from "react";
import "./about.scss";
import Heart from "../../assets/images/heart.svg";
import Culture from "../../assets/images/culture.svg";
import Travel from "../../assets/images/travel.svg";
import Match from "../../assets/images/matches.svg";
import Software from "../../assets/images/softwareengineer.svg";
import Aboutimg from "../../assets/images/about-img.svg";
import Aboutimg2 from "../../assets/images/about-img-two.svg";
import Aboutimg3 from "../../assets/images/about-img-three.svg";
import Tabimg from "../../assets/images/tabs-img.svg";
import {
	FaFacebookF,
	FaInstagram,
	FaTwitter,
	FaLinkedinIn,
} from "react-icons/fa";
// import { useState } from "react";
import AboutTabs from "../../components/AboutTab";
import { aboutTabs } from "../../utils";

const About = () => {
	const [index, setIndex] = useState(1);
	const items = [
		{ src: Heart },
		{ src: Culture },
		{ src: Travel },
		{ src: Match },
		{ src: Software },
	];
	const aboutitems = [
		{ src: Aboutimg },
		{ src: Aboutimg2 },
		{ src: Aboutimg3 },
	];
	const texts = [
		"Cause We love football",
		"Cause We love Culture",
		"Cause We love Travel",
		"Cause We Have been to more than 1000 matches",
		"Cause we love to build new products",
	];
	const aboutheading = [
		"Get the Nearby Matches",
		"Get Details About Match",
		"Find Supporter around the Stadium",
	];
	const abouttexts = [
		"Lorem ipsum dolor sit amet, consetetur sadipscing  elitr, sed diam nonumy eirmod tempor invidunt ut",
		"Lorem ipsum dolor sit amet, consetetur sadipscing  elitr, sed diam nonumy eirmod tempor invidunt ut",
		"Lorem ipsum dolor sit amet, consetetur sadipscing  elitr, sed diam nonumy eirmod tempor invidunt ut",
	];
	return (
		<div className="about-container">
			<div className="Cause-section">
				<p className="heading"> Why we doing it </p>
				<p>
					Hi, we’re Shlomi and Assaf! We’re just two passionate football fans.
					Our favorite hobby is traveling the world to watch the beautiful game.
					We’ve been to England, Turkey, South Africa, Brazil, Argentina,
					Serbia, Greece, Germany, Japan, and many more. We don’t just love the
					game; we love meeting fans and discovering the unique cultures that
					make every matchday experience unforgettable.{" "}
				</p>

				<div className="gallery">
					{items.map((item, index) => (
						<div
							className="gallery-item"
							key={index}
						>
							<div>
								<img
									src={item.src}
									alt="image"
									className="gallery-image"
								/>
							</div>
							<div style={{ flexGrow: "1" }}>
								<p className="gallery-text">{texts[index]}</p>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="tab-section">
				<div
					style={{ marginTop: "20px" }}
					className="tabs"
				>
					<AboutTabs
						tabs={aboutTabs}
						index={index}
						setIndex={setIndex}
						components={{
							1: (
								<div>
									Shlomi Meiner is a software engineer with years of experience
									in both startups and large enterprises. He’s also the founder
									of EasyCoach.club, a centralized platform tackling key
									challenges for sports organizations. Assaf Hay Eden is a
									seasoned data analyst, and together, we’ve been collaborating
									on different initiatives since 2020.
								</div>
							),
							2: (
								<div>
									Football Around Me started as a solution for us, born from our
									own experiences as ‘ground hoppers’ trying to make the most of
									each trip. We realized that finding essential information –
									which games are on, where to buy tickets, the best local pubs
									for home fans – wasn’t always easy. So, we decided to create
									Football Around Me
								</div>
							),
						}}
						type="main"
					/>
				</div>
				<img
					src={Tabimg}
					alt="image"
					className="tabimg"
				/>
			</div>
			<div>
				<p className="about-title">About App</p>
				<p className="about-detail">
					Football Around Me is built for football fans like us, helping you
					find matches in your area or in any city you plan to visit. We provide
					match details (date, time, stadium, teams, and stats) and showcase
					nearby pubs, restaurants, and hotels to complete the match-day
					experience. Save matches to your ‘Wishlist’ for future adventures or
					keep track of games you’ve attended in your personal Journal. Whether
					you’re planning your first football trip or are a seasoned ground
					hopper, we’re here to make sure every match you attend is as memorable
					as it should be.
				</p>
				<div className="about-gallery">
					{aboutitems.map((item, index) => (
						<div
							className="about-item"
							key={index}
						>
							<img
								src={item.src}
								alt="about-image"
								className="about-gallery-image"
							/>
							<p className="about-text">{aboutheading[index]}</p>
							{/* <p className="about-details">{abouttexts[index]}</p> */}
						</div>
					))}
				</div>
			</div>
			<div className="social-section">
				<p className="social-text"> Follow Us </p>
				<div className="social-icons">
					<FaFacebookF />
					<FaInstagram />
					<FaTwitter />
					<FaLinkedinIn />
				</div>
			</div>
		</div>
	);
};

export default About;
