import React from "react";
import AdSense from "react-adsense";
import PropTypes from "prop-types";

const Ad = ({ styles, slot }) => {

  const adUrl = `https://www.google.com/adsense/ads?slot=${slot}`;  // Construct the URL for the ad
  return (
    <a href={adUrl} target="_blank" rel="noopener noreferrer">
      <AdSense.Google
        client="ca-pub-9526203211305602"
        slot={slot}
        style={styles}
        format=""
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      />
    </a>
  );
};

Ad.propTypes = {
  styles: PropTypes.object,
  slot: PropTypes.string,
};

export default Ad;
