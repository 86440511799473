import React from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import "./navigationTabs.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import { clearCookies } from "../../utils/cookies";
import { logout } from "../../redux/auth/authActions";
import { useHistory } from "react-router-dom";

const NavigationTabs = ({ data, dropdown, setDropdown }) => {
	const location = useLocation();
	const { user } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleLogout = () => {
		try {
			clearCookies();
			dispatch(logout());
			history.push(`/`);
		} catch (error) {
			console.log("Error", error);
		}
	};

	return (
		<div className={`header-tabs ${dropdown && "mobile-header-tabs"}`}>
			{data.map((item) => {
				const isActive = location.pathname === item.url;
				if (item.type === "spa") {
					return (
						<div key={item.id}>
							<NavLink
								exact={item.url === "/"}
								className={`header-tab-item ${isActive ? "active" : ""}`}
								to={item.url}
								onClick={() => {
									console.log("drop", dropdown);
									setDropdown(false);
								}}
							>
								<span>{item.title}</span>
							</NavLink>
						</div>
					);
				} else {
					return (
						<a
							className="mob-dropdown-item"
							key={item.id}
							href={item.url}
							onClick={() => {
								console.log("drop", dropdown);
								setDropdown(false);
							}}
						>
							{item.title}
						</a>
					);
				}
			})}

			{user?.email && (
				<>
					<Link
						to="/settings"
						className="mob-dropdown-item"
						onClick={() => {
							console.log("drop", dropdown);
							setDropdown(false);
						}}
					>
						Settings
					</Link>

					<Link
						to="/wishlist"
						className="mob-dropdown-item"
						onClick={() => {
							console.log("drop", dropdown);
							setDropdown(false);
						}}
					>
						Wishlist
					</Link>

					<Link
						to="#"
						onClick={handleLogout}
						className="mob-dropdown-item"
					>
						Logout
					</Link>
				</>
			)}
			{!user?.email && (
				<Link
					to="/sign-in"
					className="mob-dropdown-item"
					onClick={() => {
						console.log("drop", dropdown);
						setDropdown(false);
					}}
				>
					Login
				</Link>
			)}
		</div>
	);
};

NavigationTabs.propTypes = {
	data: PropTypes.array,
	dropdown: PropTypes.bool,
	setDropdown: PropTypes.func,
};

export default NavigationTabs;
