// mixpanelUtil.js
import mixpanel from "mixpanel-browser";

mixpanel.init("1d9b4d13a615104d6db9075ed84f5192");

const env_check = process.env.NODE_ENV === "production";
const isCookiesRejected = localStorage.getItem("cookieConsent") === "rejected";

const Mixpanel = {
	identify: (id) => {
		if (env_check && !isCookiesRejected) mixpanel.identify(id);
	},
	alias: (id) => {
		if (env_check && !isCookiesRejected) mixpanel.alias(id);
	},
	track: (name, props) => {
		if (env_check && !isCookiesRejected) mixpanel.track(name, props);
	},
	people: {
		set: (props) => {
			if (env_check && !isCookiesRejected) mixpanel.people.set(props);
		},
	},
};

export default Mixpanel;
