/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import "./cookiePopUp.scss";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const CookiePopUp = ({ handleAcceptAll, handleRejectAll }) => {
	const [isSeenCookiePopUp, setIsSeenCookiePopUp] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname !== "/cookie-policy") {
			const popupSeen = localStorage.getItem("seenCookiePopUp");
			if (!popupSeen) {
				setTimeout(() => setIsSeenCookiePopUp(true), 2000);
			}
		}
	}, [location.pathname]);

	const handleClosePopUp = () => {
		setIsClosing(true);
		setTimeout(() => {
			setIsSeenCookiePopUp(false);
			localStorage.setItem("seenCookiePopUp", "true");
		}, 500);
	};

	if (!isSeenCookiePopUp) return null;

	return (
		<div className={`bottom-sheet ${isClosing ? "closing" : ""}`}>
			<div className="sheet-container">
				<p className="heading">We Value your Privacy</p>
				<p className="text">
					We use cookies to enhance browser experience, serve personalized ads
					or content, and analyze our traffic. By clicking "Accept All", you
					consent to our use of cookies.{" "}
					<Link
						to="/cookie-policy"
						target="_blank"
						rel="noopener noreferrer"
					>
						Cookie Policy
					</Link>
				</p>

				<div className="btn-container">
					<button
						className="reject-all-button"
						onClick={() => {
							handleRejectAll();
							handleClosePopUp();
						}}
					>
						Reject All
					</button>
					<button
						className="accept-all-button"
						onClick={() => {
							handleAcceptAll();
							handleClosePopUp();
						}}
					>
						Accept All
					</button>
				</div>
			</div>
		</div>
	);
};

// Prop types validation
CookiePopUp.propTypes = {
	handleAcceptAll: PropTypes.func.isRequired,
	handleRejectAll: PropTypes.func.isRequired,
};

export default CookiePopUp;
