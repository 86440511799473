import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./pop-up.scss";
import img from "../../assets/images/reviewUs.png";
import { useHistory } from "react-router-dom";

const FeedbackPopUp = () => {
	const [isSeenPopUp, setIsSeenPopUp] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const popupSeen = localStorage.getItem("seenFeedbackPopUp");
		if (!popupSeen) {
			setIsSeenPopUp(true);
		}
	}, []);

	const handleClose = () => {
		setIsSeenPopUp(false);
		localStorage.setItem("seenFeedbackPopUp", "true");
	};

	const handleContainerClick = (e) => {
		e.stopPropagation();
	};

	if (!isSeenPopUp) return null;

	return (
		<Modal
			showCloseIcon={true}
			open={isSeenPopUp}
			onClose={handleClose}
			onOverlayClick={handleClose}
			center
			classNames={{
				overlay: "custom-overlay",
				modal: "custom-modal",
			}}
		>
			<div
				className="container"
				onClick={handleContainerClick}
			>
				<p style={{ fontSize: "16px", fontWeight: "bold", color: "#7444c2" }}>
					Feedback
				</p>
				<p style={{ fontSize: "14px", textAlign: "center" }}>
					You&apos;re using the beta version of Football Around Me! Have
					feedback, ideas, or noticed a bug? We&apos;d love to hear from you!
				</p>
				<img
					src={img}
					alt="Feedback Illustration"
					className="feedback-image"
				/>
				<button
					className="contact-us-button"
					onClick={() => {
						history.push("/contact");
						handleClose();
					}}
				>
					Contact Us
				</button>
			</div>
		</Modal>
	);
};

export default FeedbackPopUp;
