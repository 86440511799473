import React from "react";
import PropTypes from "prop-types";

import "./customHeaderButton.scss";

const CustomHeaderButton = ({
	bgColor,
	textColor,
	text,
	url,
	logo,
	secondaryText,
	onClick,
	isActive,
}) => {
	const functionaddHttps = (url) => {
		if (!/^https?:\/\//i.test(url)) {

			url = 'https://' + url;
		}
		return url;
	}
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={url}
			onClick={(e) => {
				e.preventDefault();
				let updatedUrl = functionaddHttps(url);
				if (onClick) {
					onClick();
				}
				window.open(updatedUrl, "_blank");
			}}
			className={`header-btn-container ${text === "Kiwi" && "skyscanner-btn"}
      ${text === "Website" && "website-btn"}
     `}
			style={{
				backgroundColor: bgColor,
				color: textColor,
				border: isActive ? "2px solid #FF5733" : "2px solid transparent", // Change border color when active
			}}
		>
			<span
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
				className={`${text === "Website" && "web-btn"}`}
			>
				{logo}
			</span>
			<span
				className={`${text === "Website" && "web-btn-txt"} ${
					text === "Kiwi" && "skyscanner-btn-txt"
				}`}
			>
				{text}
			</span>
			{/* <span className="secondary-txt">{secondaryText}</span> */}
		</a>
	);
};

CustomHeaderButton.propTypes = {
	bgColor: PropTypes.string,
	textColor: PropTypes.string,
	text: PropTypes.string,
	url: PropTypes.string,
	secondaryText: PropTypes.string,
	logo: PropTypes.any,
	isActive: PropTypes.bool, // New Prop
};

export default CustomHeaderButton;
