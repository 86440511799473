import { SET_ACTION_TRIGGERED } from './currentLoactionTypes';

// Initial state
const initialState = {
  actionTriggered: false, // This state will track whether the action is triggered
};

// Reducer to handle state updates based on actions
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTION_TRIGGERED:
      return {
        ...state,
        actionTriggered: action.payload,
      };
    default:
      return state;
  }
};
