export const formatTravelTime = (time) => {
	if (time === "NOT AVAILABLE" || !time) return "N/A";

	// Handle string input with "hours/minutes" format
	if (typeof time === "string" && /(\d+\s*hours?|\d+\s*mins?)/i.test(time)) {
		return time
			.replace(/hours?/i, "hr")
			.replace(/mins?/i, "m")
			.trim(); // Standardize format to "Xhr Ym"
	}

	// Convert time to a number (assumes total minutes if not in string format)
	const totalMinutes = parseInt(time, 10);

	if (isNaN(totalMinutes)) return "N/A"; // Return N/A if the value is invalid

	// Calculate hours and minutes
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	// Format the time as "Xhr Ym"
	return `${hours > 0 ? `${hours}hr ` : ""}${
		minutes > 0 ? `${minutes}m` : ""
	}`.trim();
};
