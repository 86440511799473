/* eslint-disable react/no-unescaped-entities */
import React from "react";
import "./cookiePolicy.scss";

const cookiePolicy = () => {
	return (
		<>
			<div
				className="policy-container"
				style={{ padding: "50px 80px" }}
			>
				<div role="">
					<h3>Cookie Policy for Football Around Me</h3>
					<h3>Effective Date: 01/12/2024</h3>
					<p>
						This Cookie Policy explains how{" "}
						<span style={{ fontWeight: "bold" }}> Football Around Me </span>{" "}
						("we," "our," or "us") uses cookies and similar technologies to
						enhance your experience, improve our platform, and enable our
						affiliate partnerships.
					</p>
				</div>
				<div className="border-line"></div>
				<div role="what are cookies">
					<h3>What Are Cookies?</h3>
					<p>
						Cookies are small text files stored on your device (e.g., computer,
						tablet, or smartphone) when you visit a website or use an app. They
						help us remember your preferences, analyze your interactions, and
						personalize your experience.
					</p>
				</div>
				<div className="border-line"></div>

				<div role="Types of Cookies We Use">
					<h3>Types of Cookies We Use</h3>
					<ol style={{ paddingLeft: "2rem" }}>
						<li style={{ fontWeight: "bold" }}>Essential Cookies</li>
						<p>
							These cookies are necessary for the functioning of our
							website/app. They enable basic features like page navigation,
							security, and user authentication. Without these cookies, the
							website/app cannot operate properly.
						</p>
						<li style={{ fontWeight: "bold" }}>Analytics Cookies</li>
						<p>
							We use tools like{" "}
							<span style={{ fontWeight: "bold" }}> Mixpanel </span> and
							<span style={{ fontWeight: "bold" }}>Microsoft Clarity</span> to
							collect anonymized data on how users interact with our platform.
							These cookies help us:
						</p>
						<ul style={{ paddingLeft: "2rem" }}>
							<li>Understand usage patterns.</li>
							<li>Identify and fix bugs.</li>
							<li>Improve overall user experience.</li>
						</ul>
					</ol>
					<p>These cookies are non-essential and require your consent.</p>
					<ol
						start={3}
						style={{ paddingLeft: "2rem" }}
					>
						<li style={{ fontWeight: "bold" }}>Functional Cookies</li>
						<p>
							These cookies help us enhance your experience by remembering your
							preferences, such as language settings or dismissed popups. While
							they improve usability, they are not essential for the website/app
							to work.
						</p>
						<li style={{ fontWeight: "bold" }}>Affiliate Cookies</li>
						<p>
							We work with affiliate partners for tickets, hotels, and flights
							to offer a seamless match-day planning experience. Affiliate
							cookies track referrals from our platform to these partners,
							ensuring we receive commissions when purchases are made.
						</p>
					</ol>
					<p>Key details about affiliate cookies:</p>
					<ul style={{ paddingLeft: "4rem" }}>
						<li>They may remain on your device for up to 30 days.</li>
						<li>
							They are used to track referral data and do not collect personally
							identifiable information.
						</li>
						<li>
							These cookies are non-essential and will only be activated with
							your consent.
						</li>
					</ul>
					<ol
						style={{ paddingLeft: "2rem" }}
						start={5}
					>
						{" "}
						<li style={{ fontWeight: "bold" }}>
							{" "}
							Advertising Cookies{" "}
							<span style={{ fontWeight: "normal" }}> (if applicable)</span>
						</li>
					</ol>
				</div>

				<div className="border-line"></div>

				<div role="Why We Use Cookies">
					<h3 style={{ margin: "0" }}>Why We Use Cookies</h3>
					<p>We use cookies to:</p>
					<ul style={{ paddingLeft: "2rem" }}>
						<li>Provide essential functionality.</li>
						<li>Analyze user behavior to improve our platform.</li>
						<li>
							Track affiliate transactions for our ticket, hotel, and flight
							partnerships.
						</li>
						<li>Provide essential functionality.</li>
						<li>Ensure security and prevent fraud.</li>
					</ul>
				</div>

				<div className="border-line"></div>

				<div role="Managing Your Cookie Preferences">
					<h3 style={{ margin: "0" }}>Managing Your Cookie Preferences</h3>
					<ol style={{ paddingLeft: "2rem" }}>
						<li style={{ fontWeight: "bold" }}>
							Consent for Non-Essential Cookies
						</li>
						<p>
							We will only use analytics, functional, affiliate, and advertising
							cookies if you provide explicit consent. Essential cookies are
							exempt from this consent.
						</p>
						<li style={{ fontWeight: "bold" }}>How to Manage Cookies</li>
						<p>
							You can modify or withdraw your cookie preferences at any time:
						</p>
						<ul style={{ paddingLeft: "2rem" }}>
							<li>
								Use our Cookie Settings tool (available in the footer or
								settings menu).
							</li>
							<li>
								Adjust your browser settings to block or delete cookies.
								However, this may affect some features of the platform.
							</li>
						</ul>
					</ol>
				</div>

				<div className="border-line"></div>

				<div role="Third-Party Tools We Use">
					<h3 style={{ margin: "0" }}>Third-Party Tools We Use</h3>
					<p style={{ margin: "0" }}>
						We use trusted third-party tools to analyze user behavior and manage
						affiliate tracking:
					</p>
					<ul style={{ paddingLeft: "2rem" }}>
						<li>
							<span style={{ fontWeight: "bold" }}>Mixpanel:</span> Tracks user
							interactions for analytics purposes. For more details, visit{" "}
							<br /> Mixpanel Privacy Policy.
						</li>
						<li>
							<span style={{ fontWeight: "bold" }}>Microsoft Clarity: </span>
							Provides insights into user behavior through heatmaps and session
							recordings. Learn more at Microsoft Clarity Privacy Policy.
						</li>
						<li>
							<span style={{ fontWeight: "bold" }}>Affiliate Networks:</span>{" "}
							Our affiliate partners may place cookies to track referrals.{" "}
							<br /> Please refer to their individual privacy policies for more
							details.
						</li>
					</ul>
				</div>

				<div className="border-line"></div>

				<div role="Impact of Declining Cookies">
					<h3 style={{ margin: "0" }}>Impact of Declining Cookies</h3>
					<p style={{ margin: "0" }}>If you decline non-essential cookies:</p>
					<ul style={{ paddingLeft: "2rem" }}>
						<li>
							You can still browse Football Around Me and access core features.
						</li>
						<li>
							Certain functionalities, like personalized recommendations or
							affiliate tracking, may be limited.
						</li>
					</ul>
				</div>

				<div className="border-line"></div>

				<div role="Changes to This Policy">
					<h3 style={{ margin: "0" }}>Changes to This Policy</h3>
					<p style={{ margin: "0" }}>
						We may update this Cookie Policy periodically to reflect changes in
						our practices or legal requirements. Any updates will be posted
						here, with the "Effective Date" revised accordingly.
					</p>
				</div>

				<div className="border-line"></div>

				<div role="contact-us">
					<h3 style={{ margin: "0" }}>Contact Us</h3>
					<p style={{ margin: "0" }}>
						If you have questions or concerns about this Cookie Policy, please
						use the contact us form.
					</p>
				</div>
			</div>
		</>
	);
};

export default cookiePolicy;
