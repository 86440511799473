import React from "react";
import PropTypes from "prop-types";

import "./customApplyButton.scss";

const CustomApplyButton = ({
	text,
	handlePress,
	isFetching,
	classNameContainer,
	disabled = false,
}) => {
	return (
		<div
			className={`apply-btn-container ${
				classNameContainer ? classNameContainer : ""
			}`}
		>
			<div
				onClick={() => !isFetching && !disabled && handlePress()}
				className={`custom-apply-btn${
					isFetching || disabled ? "-disable" : ""
				}`}
				// style={{
				// 	cursor: isFetching || disabled ? "not-allowed" : "pointer",
				// 	pointerEvents: isFetching || disabled ? "none" : "auto",
				// }}
			>
				{text}
			</div>
		</div>
	);
};

CustomApplyButton.propTypes = {
	handlePress: PropTypes.func,
	isFetching: PropTypes.bool,
	text: PropTypes.string,
	disabled: PropTypes.bool,
	classNameContainer: PropTypes.string,
};

export default CustomApplyButton;
