import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
// import * as Sentry from "@sentry/react";
import moment from "moment";

import Header from "./components/Header";
import {
	fetchLeaguesStart,
	fetchSelectedLeague,
} from "./redux/leagues/leaguesActions";
import {
	fetchUserLocationStart,
	fetchUserLocationSuccess,
	fetchUserLocationFailure,
} from "./redux/userLocation/userLocationActions";
import { getUserLocation } from "./helpers/getUserLocation";
import { fetchMapDetails } from "./redux/map/mapActions";
import { selectFixturesFetching } from "./redux/fixtures/fixturesSelectors";
import "./index.css";
import { fetchDimensions } from "./redux/screenDimensions/screenDimensionsActions";
import {
	fetchFixturesByLocationStart,
	resetFixtures,
	// fetchFixturesByLeaguesStart,
} from "./redux/fixtures/fixturesActions";
import { getUrlParams } from "./utils";
import { fetchFormValues } from "./redux/formValues/formValuesActions";
import {
	selectHeight,
	selectWidth,
} from "./redux/screenDimensions/screenDimensionsSelectors";
// import MobileAnimation from "./components/MobileAnimation";
import MainRoutes from "./routes";
import { fetchLiveMatchesDate } from "./redux/live/liveActions";
import { selectIsFetchingLiveMatches } from "./redux/live/liveSelectors";
import { initGA } from "./utils/initGA";
import { selectNormalizedLeaguesList } from "./redux/leagues/leaguesSelectors";
import { fetchWishlistRequest } from "./redux/auth/authActions";
import Mixpanel from "./utils/mixPanel";
import Footer from "./components/Footer/Footer";
import {
	selectAreFixturesDetailsFetched,
	selectAreFixturesFetched,
	selectIsSharedFixture,
} from "./redux/sidebar/sidebarSelectors";
import { resetFixtureDetails } from "./redux/fixtureDetails/fixtureDetailsActions";
import { resetFilteringValues } from "./redux/filteringValues/filteringValuesActions";
import { resetSidebarDetails } from "./redux/sidebar/sidebarActions";
import FeedbackPopUp from "./components/PopUp";
import CookiePopUp from "./components/CookiePopUp";
// import ErrorMessage from "./components/ErrorMessage";
// import mixpanel from "mixpanel-browser";
initGA();
// mixpanel.init("1d9b4d13a615104d6db9075ed84f5192", {
//   debug: true,
//   track_pageview: true,
//   persistence: "localStorage",
// });
// Mixpanel.track('Home screen');
function App({
	fetchLeaguesStart,
	fetchUserLocationStart,
	// fetchUserLocationFailure,
	fetchUserLocationSuccess,
	fetchMapDetails,
	fetchDimensions,
	// fetchFixturesByLocationStart,
	fetchFormValues,
	height,
	isFetchingLiveMatches,
	width,
	leaguesList,
	isFetchingFixtures,
	fetchSelectedLeague,
	// fetchFixturesByLeaguesStart,
	fetchWishlistRequest,
	areFixturesFetched,
	areFixturesDetailsFetched,
	isSharedFixture,
}) {
	useEffect(() => {
		fetchLeaguesStart();
	}, []);
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const [locationFetched, setLocationFetched] = useState(false);
	const platform = typeof window !== "undefined" ? "web" : "mobile";
	const isMobileScreen =
		/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
			navigator.userAgent
		);
	const [cookieConsent, setCookieConsent] = useState(null);

	useEffect(() => {
		const consent = localStorage.getItem("cookieConsent");
		setCookieConsent(consent);
	}, []);

	useEffect(() => {
		if (cookieConsent === "accepted") {
			// Load Clarity script
			const clarityScript = document.createElement("script");
			clarityScript.src = "https://www.clarity.ms/tag/otur2zek49";
			clarityScript.async = true;
			document.body.appendChild(clarityScript);
		}
	}, [cookieConsent]);

	const handleRejectAll = () => {
		localStorage.setItem("cookieConsent", "rejected");
		localStorage.setItem("seenCookiePopUp", true);

		setCookieConsent("rejected");
	};

	const handleAcceptAll = () => {
		localStorage.setItem("cookieConsent", "accepted");
		localStorage.setItem("seenCookiePopUp", true);
		setCookieConsent("accepted");
	};

	function isValidLatLng(lat, lng) {
		const latNum = parseFloat(lat);
		const lngNum = parseFloat(lng);

		return (
			!isNaN(latNum) &&
			!isNaN(lngNum) &&
			latNum >= -90 &&
			latNum <= 90 &&
			lngNum >= -180 &&
			lngNum <= 180
		);
	}

	// console.log({ BUILD: process.env.BRANCH, all: process.env });
	useEffect(() => {
		const { radius, dates, lat, lng, address, iso_a2, leagues } = getUrlParams(
			window.location.href
		);
		if (radius) {
			let datesArray = [];
			const _format = "YYYY-MM-DD";
			let firstDay = moment(dates.split(",")[0]);
			let lastDate = moment(dates.split(",")[1]).format(_format);
			while (firstDay.format(_format) <= lastDate) {
				datesArray.push(firstDay.format(_format));
				firstDay = firstDay.add(1, "days");
			}
			datesArray = [...datesArray];

			fetchFormValues({
				dates: datesArray,
				currentDate: datesArray[0],
				radius: +radius,
				searchedLocation: { lat: +lat, lng: +lng },
				countryCode: iso_a2,
				address: decodeURIComponent(address).replace(/\+/g, " "),
			});
			if (lat && lng && isValidLatLng(lat, lng)) {
				console.log("Using URL params location:", { lat, lng });

				fetchMapDetails({ center: [+lat, +lng] });
			}
		} else {
			if (leaguesList && leagues) {
				fetchSelectedLeague(leagues.split(",").map((x) => leaguesList[+x]));
			}
		}

		getUserLocation(fetchUserLocationStart)
			.then((res) => {
				console.log(
					"User location fetched successfully:-------------------",
					res
				);

				fetchUserLocationSuccess(res);
				// console.log(res, "getUserLocation");
				if (!(lat && lng)) {
					console.log(
						"Setting map details using fetched location:--------------------------",
						res.coordinates
					);

					fetchMapDetails({ center: res.coordinates });
				}
				setLocationFetched(true);
				Mixpanel.track("Enabled location", {
					platform: platform,
					application_type: platform === "web" ? "web app" : "mobile app",
				});
				// if (!radius && !leagues) {
				//   fetchFixturesByLocationStart(true);
				// } else if (leagues) {
				//   fetchFixturesByLeaguesStart();
				// }
			})
			.catch((err) => {
				console.error(
					"Failed to fetch user location:---------------------------",
					err
				);

				if (!lat || !lng) {
					const LondonCords = {
						countryCode: "GB",
						coordinates: {
							lat: 51.5073509,
							lng: -0.1277583,
						},
						address: "London, UK",
					};
					Mixpanel.track("Disabled location", {
						platform: platform,
						application_type: platform === "web" ? "web app" : "mobile app",
					});
					console.log("Using fallback location (London):", LondonCords);

					fetchUserLocationSuccess(LondonCords);
					fetchMapDetails({ center: LondonCords.coordinates });
				}
				setLocationFetched(true);
				// fetchFixturesByLocationStart(true);

				// we have had a mistake please try again
			});
	}, [fetchUserLocationStart]);

	useEffect(() => {
		window.addEventListener("resize", () => {
			if (width !== window.innerWidth) {
				fetchDimensions({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
		});
	}, []);
	useEffect(() => {
		if (locationFetched && user && user?._id) {
			fetchWishlistRequest(user._id);
		}
	}, [locationFetched, user]);
	useEffect(() => {
		return () => {
			if (isSharedFixture) {
				dispatch(resetFixtures());
				dispatch(resetFixtureDetails());
				dispatch(resetFilteringValues());
				dispatch(resetSidebarDetails());
			}
			// resetLiveModals();
		};
	}, [window.location.href, leaguesList]);
	return (
		<div style={{ position: "relative", height: "100%", overflow: "hidden" }}>
			<div
				className="app-container"
				style={{ height: height && width && width <= 1000 ? height : "100vh" }}
			>
				<CookiePopUp
					handleAcceptAll={handleAcceptAll}
					handleRejectAll={handleRejectAll}
				/>
				<FeedbackPopUp />
				<div
					className={`${
						(width <= 1000 && isFetchingFixtures) ||
						(width <= 1000 && isFetchingLiveMatches)
							? "abs"
							: ""
					}`}
				>
					<Header />

					{/* <Sentry.ErrorBoundary fallback={<ErrorMessage />}> */}
					<MainRoutes />
					{/* </Sentry.ErrorBoundary> */}
				</div>
				{/* {(width <= 1000 && isFetchingFixtures) ||
        (width <= 1000 && isFetchingLiveMatches) ? (
          <MobileAnimation
            height={height}
            width={width}
            isFetchingFixtures={isFetchingFixtures}
          />
        ) : null} */}
			</div>
			{(isMobileScreen && !(areFixturesFetched || areFixturesDetailsFetched)) ||
			!isMobileScreen ? (
				<Footer />
			) : (
				""
			)}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	fetchLeaguesStart: () => dispatch(fetchLeaguesStart()),
	fetchWishlistRequest: (userId) => dispatch(fetchWishlistRequest(userId)),
	fetchUserLocationStart: () => dispatch(fetchUserLocationStart()),
	fetchUserLocationSuccess: (payload) =>
		dispatch(fetchUserLocationSuccess(payload)),
	fetchUserLocationFailure: (payload) =>
		dispatch(fetchUserLocationFailure(payload)),
	fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
	fetchDimensions: (payload) => dispatch(fetchDimensions(payload)),
	fetchFixturesByLocationStart: (payload) =>
		dispatch(fetchFixturesByLocationStart(payload)),
	fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
	fetchLiveMatchesDate: (payload) => dispatch(fetchLiveMatchesDate(payload)),
	fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
	// fetchFixturesByLeaguesStart: () => dispatch(fetchFixturesByLeaguesStart()),
});

const mapStateToProps = (state) => ({
	height: selectHeight(state),
	width: selectWidth(state),
	isFetchingFixtures: selectFixturesFetching(state),
	isFetchingLiveMatches: selectIsFetchingLiveMatches(state),
	leaguesList: selectNormalizedLeaguesList(state),
	areFixturesFetched: selectAreFixturesFetched(state),
	areFixturesDetailsFetched: selectAreFixturesDetailsFetched(state),
	isSharedFixture: selectIsSharedFixture(state),
});

App.propTypes = {
	isFetchingFixtures: PropTypes.bool,
	fetchLeaguesStart: PropTypes.func,
	fetchWishlistRequest: PropTypes.func,
	fetchUserLocationStart: PropTypes.func,
	fetchSelectedLeague: PropTypes.func,
	fetchLiveMatchesDate: PropTypes.func,
	fetchUserLocationFailure: PropTypes.func,
	fetchUserLocationSuccess: PropTypes.func,
	fetchMapDetails: PropTypes.func,
	fetchFixturesByLeaguesStart: PropTypes.func,
	fetchDimensions: PropTypes.func,
	fetchFixturesByLocationStart: PropTypes.func,
	width: PropTypes.number,
	fetchFormValues: PropTypes.func,
	leaguesList: PropTypes.object,
	isFetchingLiveMatches: PropTypes.bool,
	height: PropTypes.number,
	areFixturesFetched: PropTypes.bool,
	areFixturesDetailsFetched: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
